import { CalendarSpec } from 'moment';

import { environment } from '@environment/environment';

import { PagePaths } from '@shared/constants/page-paths';

import { Lookup } from './state/lookup/lookup';

export class Constants {
    public static INITIAL_PAGE_SIZE: number = 10;
    public static PASSWORD_PATTERN: string =
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{1,}$';
    public static GMAIL_URL: string = 'https://mail.google.com/mail/u/0/#inbox';
    public static VSC_RESOURCE_PAGE_URL: string =
        'http://member-support.enliveo.com/';
    public static VSC_HELP_CENTER_PAGE_URL: string =
        'http://facilitator-support.enliveo.com';
    public static readonly CHAT_DEFAULT_IMAGE_URL =
        'https://stvsc002.blob.core.windows.net/image/0f2507db-c1c0-403d-b3d4-6932b8ca5ea8.jpg';
    public static readonly PROMOTION_DEFAULT_IMAGE_URL =
        'https://stvsc002.blob.core.windows.net/image/72A28736-08B6-4011-B10E-E02A445CB873.jpg';
    public static INVALID_DATE: string = 'Invalid Date';
    public static KEY_BACKSPACE: string = 'Backspace';
    public static DEFAULT_TIME_ZONE: string = 'America/New_York';
    public static DEFAULT_COUNTRY: string = 'United States';
    public static DEFAULT_AGE_RANGE_ID: string =
        '6d4bb7c9-79c0-427b-87a1-5fd589bbe133';

    // Date format constants
    public static DATE_FORMAT_US: string = 'MM/DD/YYYY';
    public static DATE_FORMAT_ISO: string = 'YYYY-MM-DD';
    public static SHORT_DATE_FORMAT: string = 'MM/DD';

    public static DATE_TIME_FORMAT_ISO: string = 'YYYY-MM-DDTHH:mm';
    public static TIME_FORMAT_US: string = 'hh:mm A';
    public static TIME_FORMAT_24h: string = 'HH:mm';
    public static readonly FULL_DATE_TIME_FORMAT_US: string = `${Constants.DATE_FORMAT_US} ${Constants.TIME_FORMAT_US}`;
    public static IS_RESET_FORM_ATTR = 'isResetForm';
    public static MS_PER_MINUTE: number = 60000;
    public static ERROR_STATUS_CODE_403: number = 403;
    public static ERROR_STATUS_CODE_404: number = 404;
    public static ERROR_STATUS_CODE_400: number = 400;
    public static ERROR_STATUS_CODE_401: number = 401;
    public static ERROR_STATUS_CODE_422: number = 422;
    public static MOMENT_FORMAT_TYPES = {
        sameDay: 'MMMM D · [Today]',
        nextDay: 'MMMM D · [Tomorrow]',
        nextWeek: 'MMMM D · dddd',
        sameElse: 'MMMM D · dddd',
        lastDay: 'MMMM D · [Yesterday]',
        lastWeek: 'MMMM D · dddd',
    } as CalendarSpec;
    public static CLASS_DETAILS_PATH_REGEX: RegExp =
        /^\/classes\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/sessions\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    public static CHATS_REFRESH_INTERVAL_MS: number =
        5 * Constants.MS_PER_MINUTE;
    public static readonly CHAT_DURATION_MIN: number = 120;
    public static readonly CHAT_DEFAULT_BACK_URL: string =
        PagePaths.Public.Chats;

    public static readonly FORUM_DEFAULT_BACK_URL: string =
        PagePaths.Public.Forum;

    public static readonly CLASSROOM_DEFAULT_BACK_URL: string =
        PagePaths.Public.Classes;

    public static readonly VIDEOS_DEFAULT_BACK_URL: string =
        PagePaths.Public.VideoLibrary;

    public static readonly SINGLE_OCCURRENCE_TITLE: string =
        'Single occurrence';

    // URL paths
    public static CHATS_SCHEDULE_PATH: string = '/chats/schedule';

    // Properties
    public static readonly START_DATE_TIME_PROPERTY: string = 'startDateTime';

    public static ZOOM_DESKTOP_NAVIGATION_URL: string = 'zoommtg://zoom.us/';
    public static ZOOM_MOBILE_NAVIGATION_URL: string = 'zoomus://zoom.us/';

    public static DEV_ZOOM_HTML_PATH: string = '/zoom/meeting.html';
    public static PROD_ZOOM_HTML_PATH: string = '/zoom/meeting-production.html';
    public static PHONE_TYPES: Lookup[] = [
        {
            id: 'Mobile',
            name: 'Mobile',
        },
        {
            id: 'Home',
            name: 'Home',
        },
        {
            id: 'Fax',
            name: 'Fax',
        },
        {
            id: 'Caregiver',
            name: 'Caregiver',
        },
    ];

    // Cookie Consent status
    public static COOKIE_CONSENT_ALLOWED: string = 'allow';
    public static COOKIE_CONSENT_DENIED: string = 'deny';
    public static COOKIE_EXPIRED: string =
        'cookieconsent_status=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=';
    public static SELFHELP_ORGANIZATION_ID: string =
        '24018928-5587-49e7-9efe-3053202a5faa';

    // Today's Schedule refresh interval
    public static TODAYS_SCHEDULE_REFRESH_INTERVAL_MS: number =
        environment.todaysScheduleRefreshInterval * Constants.MS_PER_MINUTE;

    // File signature validation
    public static FILE_SIGNATURE: Map<string, string[]> = new Map()
        .set('image/png', ['89504e47da1aa'])
        .set('image/svg+xml', ['3c73766720', '3c3f786d6c'])
        .set('image/jpg', [
            'ffd8ffe0',
            'ffd8ffe1',
            'ffd8ffe8',
            'ffd8ffd8',
            'ffd8ffee',
        ])
        .set(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ['504b34']
        );
    public static BYTES_NUMBER_FOR_SIGNATURE_VALIDATION: Map<string, number> =
        new Map()
            .set('image/png', 8)
            .set('image/svg+xml', 5)
            .set('image/jpg', 4)
            .set(
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                4
            )
            .set('application/vnd.ms-excel', 8);
    public static MIME_TYPES_FROM_EXTENSION: Map<string, string> = new Map()
        .set('.png', 'image/png')
        .set('.svg', 'image/svg+xml')
        .set('.jpg', 'image/jpg');
    public static FILE_UPLOAD_MIME_TYPES_FROM_EXTENSIONS: Map<string, string> =
        new Map()
            .set('.xls', 'application/vnd.ms-excel')
            .set(
                '.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            );
    // Zoom download links
    public static ZOOM_WINDOWS_DOWNLOAD_URL: string = `https://zoom.us/client/5.11.3.7123/ZoomInstallerFull.exe?archType=x86`;
    public static ZOOM_MACOS_DOWNLOAD_URL: string = `https://zoom.us/client/latest/zoomusInstallerFull.pkg`;
    public static JITSI_SCRIPT_NAME: string = 'external_api.js';
    // Languages native translations
    public static LANGUAGES_NATIVE_NAMES = {
        English: 'English',
        Spanish: 'Español',
        Chinese: '中文',
        Russian: 'Pусский',
        Korean: '한국인',
        Arabic: 'عربي',
        Hebrew: 'עִברִית',
        Vietnamese: 'Tiếng Việt',
        Universal: 'Universal',
    };
    public static INREVIEW_USERS_DEFAULT_SORT_BY = 'CreatedAt';
    public static PENDING_USERS_DEFAULT_SORT_BY = 'ActivationTokenCreatedAt';
    public static ENGLISH_LANGUAGE_ID: string =
        '03031B76-2EC4-456A-9577-48EC84F17810';
    public static UNIVERSAL_LANGUAGE_ID: string =
        '8f6b6473-414d-4d6d-9378-e7f0d132b38f';
    public static readonly SKIP_HTTP_ERROR_INTERCEPTOR_HEADER: string =
        'X-Skip-Http-Error-Interceptor';
    public static USERS_BULK_UPLOAD_TEMPLATE: string =
        'https://stvsc002.blob.core.windows.net/docs/Bulk_Users_Upload_Template.xlsx';
    public static readonly AVATAR_COLORS: Array<string> = [
        '#a0e5c9',
        '#F25C42',
        '#2B3ABE',
        '#498E14',
        '#FF0943',
    ];
}
