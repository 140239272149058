<ng-container *ngIf="class$ | async as class">
    <vsc-public-event-card
        [publicEvent]="this.publicEvent$ | async"
        [orientation]="orientation"
        [verticalCardActionsTemplateRef]="verticalCardActionsTemplate"
        [horizontalCardActionsTemplateRef]="horizontalCardActionsTemplate"
        (click)="goToClassSessionDetailsPage(class.id, class.classSession?.id)"
    >
        <!-- Vertical card actions template -->
        <ng-template #verticalCardActionsTemplate>
            <ng-container
                *ngLet="
                    (isClassSessionReady$ | async) ===
                    true as isClassSessionReady
                "
            >
                <vsc-join-class
                    class="vertical-card-join"
                    #classroom
                    [classId]="class.id"
                    *ngIf="isClassSessionReady"
                    [isLiveStream]="class.isLiveStream"
                    [classSessionId]="class.classSession?.id"
                >
                    [isZoomCreated]="class.isZoomEmbedded"
                </vsc-join-class>

                <a
                    *ngIf="!isClassSessionReady && !class.isFavorite"
                    (click)="addToFavorite($event, class.id)"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="p-16 add-to-calendar-vertical"
                >
                    <vsc-icon icon="calendar-plus"></vsc-icon>
                    <span>{{ 'Add to my calendar' | transloco }}</span>
                </a>

                <a
                    *ngIf="!isClassSessionReady && class.isFavorite"
                    (click)="removeFromFavorite($event, class.id)"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center center"
                    class="p-16 remove-from-calendar-vertical"
                >
                    <vsc-icon icon="calendar-minus"></vsc-icon>
                    <span>
                        {{ 'Remove' | transloco }}
                    </span>
                </a>
            </ng-container>
        </ng-template>
        <!-- /Vertical card actions template -->

        <!-- Horizontal card actions template -->
        <ng-template #horizontalCardActionsTemplate>
            <ng-container
                *ngLet="
                    (isClassSessionReady$ | async) ===
                    true as isClassSessionReady
                "
            >
                <vsc-join-class
                    #classroom
                    [classSessionId]="class.classSession?.id"
                    *ngIf="isClassSessionReady"
                    [classId]="class.id"
                    [isLiveStream]="class.isLiveStream"
                    [isZoomCreated]="class.isZoomEmbedded"
                    (click)="$event.stopPropagation()"
                >
                </vsc-join-class>

                <a
                    *ngIf="!isClassSessionReady && !class.isFavorite"
                    (click)="addToFavorite($event, class.id)"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center"
                >
                    <vsc-icon icon="calendar-plus"></vsc-icon>
                    <span class="add-to-calendar-horizontal">{{
                        'Add to my calendar' | transloco
                    }}</span>
                </a>

                <a
                    *ngIf="!isClassSessionReady && class.isFavorite"
                    (click)="removeFromFavorite($event, class.id)"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="center"
                >
                    <vsc-icon icon="calendar-minus"></vsc-icon>
                    <span class="remove-from-calendar-horizontal">
                        {{ 'Remove' | transloco }}
                    </span>
                </a>
            </ng-container>
        </ng-template>
        <!-- /Horizontal card actions template -->
    </vsc-public-event-card>
</ng-container>
